import { type HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResourceTypes, RestApiClientService } from '@big-direkt/rest-api-client';
import { UserRepository } from '@big-direkt/state/user';
import { ErrorHandlingService, SectionLocksService } from '@big-direkt/utils/environment';
import { firstValueFrom } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { type DocumentsUnreadCountModel } from '../models/documents-unread-count.model';
import { type DocumentsModel } from '../models/documents.model';
import { type DocumentModel } from '../models';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly isSectionsLock = toSignal(inject(SectionLocksService).isEnabled('mailbox'));
    private readonly restApiClient = inject(RestApiClientService);
    private readonly userRepository = inject(UserRepository);
    private readonly isNotLoggedInOrHasSectionLock = computed(() => {
        if (!this.userRepository.isLoggedIn()) {
            return true;
        }

        return this.isSectionsLock();
    });

    public readonly documents = signal<DocumentModel[]>([]);
    public readonly errorCode = signal<number | undefined>(undefined);
    public readonly total = signal<number>(0);
    public readonly unread = signal<number>(0);

    public update(offset: number, elementsPerPage: number): void {
        if (this.isNotLoggedInOrHasSectionLock()) {
            return;
        }

        void firstValueFrom(
            this.restApiClient.load<DocumentsModel>(ResourceTypes.Documents, undefined, {
                params: {
                    cb: Date.now(),
                    offset,
                    limit: elementsPerPage,
                },
            }).pipe(
                tap(res => {
                    this.documents.set(res.documents);
                    this.total.set(res.totalCount);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.errorCode.set(err.status);

                    return this.errorHandlingService.catchError(err);
                }),
            ),
        );
    }

    public updateUnread(): void {
        if (this.isNotLoggedInOrHasSectionLock()) {
            return;
        }

        void firstValueFrom(this.restApiClient.load<DocumentsUnreadCountModel>(ResourceTypes.DocumentsUnreadCount, undefined, {
            params: {
                cb: Date.now(),
            },
        })
            .pipe(
                tap(res => {
                    this.unread.set(res.unreadCount);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.errorCode.set(err.status);

                    return this.errorHandlingService.catchError(err);
                }),
            ));
    }

    public markAsRead(): void {
        if (this.isNotLoggedInOrHasSectionLock()) {
            return
        }

        this.unread.update(count => {
            const newCount = count - 1;

            if (newCount < 0) {
                return 0;
            }

            return newCount;
        })
    }
}
